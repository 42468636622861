import React from "react";
import Layout from "../components/layout";
import HowToOrderItem from "../components/how-to-order-item";
import Breadcrumb from "../components/breadcrumb";
import GlobalConstants from "../helpers/constants";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { Grid, Typography } from "@material-ui/core";
import { GetComponentByName } from "../helpers/query-data";
import { graphql } from "gatsby";
import { PageProps } from "gatsby";
import { GQLQuery } from "../types";

type PageInputProps = { crystallize: GQLQuery };
type HowToOrderProps = PageProps<PageInputProps>;

const HowToOrder = (props: HowToOrderProps): JSX.Element => {
  const QueryData = props.data.crystallize.catalogue;
  const PathObj = GetSimplePathObj(GlobalConstants.HowToOrder);
  const DescComponent = GetComponentByName(
    QueryData.components,
    GlobalConstants.Description
  );

  return (
    <Layout currentPage="/how-to-order">
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{QueryData.name}</Typography>
        {DescComponent.content.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        {QueryData.children.map((node, index) => (
          <HowToOrderItem key={index} article={node} />
        ))}
      </Grid>
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue(path: "/articles/how-to-order") {
        name
        components {
          name
          content {
            ...H2O_richTextContent
          }
        }
        children {
          ...H2O_item
        }
      }
    }
  }

  fragment H2O_item on CRYSTALLIZE_Item {
    name
    components {
      name
      content {
        ...H2O_singleLineContent
        ...H2O_richTextContent
        ...H2O_imageContent
      }
    }
  }

  fragment H2O_singleLineContent on CRYSTALLIZE_SingleLineContent {
    text
  }

  fragment H2O_richTextContent on CRYSTALLIZE_RichTextContent {
    html
  }

  fragment H2O_imageContent on CRYSTALLIZE_ImageContent {
    images {
      url
    }
  }
`;

export default HowToOrder;
