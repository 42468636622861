import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GetComponentByName } from "../helpers/query-data";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  GQLComponent,
  GQLImageContent,
  GQLItem,
  GQLRichTextContent,
  GQLSingleLineContent,
} from "../types";

const UseStyles = makeStyles({
  accordionSummary: {
    "&.Mui-expanded": {
      backgroundColor: "#AFBD21",

      "& .MuiTypography-root": {
        fontWeight: "bold",
      },
    },

    "&:hover": {
      backgroundColor: "#AFBD21",
    },
  },

  accordionDetails: {
    display: "block",
  },

  imageWrapper: {
    textAlign: "center",
  },

  image: {
    maxWidth: "100%",
  },
});

/* Breaks the Crystallize article into an array of objects for display
 * - components -> All Crystallize rich text components
 */
const GetH2OItemDetails = (components: GQLComponent[]) => {
  return [
    {
      content: GetComponentByName(components, "Content 1")
        .content as GQLRichTextContent,
      images: GetComponentByName(components, "Images 1")
        .content as GQLImageContent,
    },
    {
      content: GetComponentByName(components, "Content 2")
        .content as GQLRichTextContent,
      images: GetComponentByName(components, "Images 2")
        .content as GQLImageContent,
    },
    {
      content: GetComponentByName(components, "Content 3")
        .content as GQLRichTextContent,
      images: GetComponentByName(components, "Images 3")
        .content as GQLImageContent,
    },
  ];
};

type HowToOrderItemProps = { article: GQLItem };

/*
 * article -> Crystallize article (long)
 */
const HowToOrderItem = (props: HowToOrderItemProps): JSX.Element => {
  const Title = GetComponentByName(props.article.components, "Title")
    .content as GQLSingleLineContent;
  const ItemDetails = GetH2OItemDetails(props.article.components);
  const Classes = UseStyles();

  return (
    <Accordion square>
      <AccordionSummary
        className={Classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="button">{Title.text}</Typography>
      </AccordionSummary>
      <AccordionDetails className={Classes.accordionDetails}>
        {ItemDetails.map((node1, index1) => {
          const content = node1.content;
          const images = node1.images;

          return (
            <div key={index1}>
              {content?.html &&
                content.html.map((node2, index2) => (
                  <div
                    key={index2}
                    className="MuiTypography-root MuiTypography-body2"
                    dangerouslySetInnerHTML={{ __html: node2 }}
                  />
                ))}
              <div className={Classes.imageWrapper}>
                {images?.images &&
                  images.images.map((node, index) => (
                    <img
                      key={index}
                      className={Classes.image}
                      src={node.url}
                      alt={Title.text}
                    />
                  ))}
              </div>
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default HowToOrderItem;
